.rb__block-table {
  margin: $section-margin 0;
  @media (min-width: $tablet-width) {
    margin: $section-margin-large 0;
  }
  table {
    border: none;
    border-spacing: 0;
    &.four-col {
      th,
      td {
        @media (min-width: 900px) {
          width: calc(100% / 4);
        }
      }
    }
    &.three-col {
      th,
      td {
        @media (min-width: 900px) {
          width: calc(100% / 3);
        }
      }
    }
    &.bt tbody tr,
    &.bt tfoot tr {
      td {
        &:before {
          color: $affinity-blue;
          width: 8em;
          padding-right: 16px;
        }
        &.no-th {
          &:before {
            display: none;
          }
          span {
            font-size: 20px;
          }
        }
      }
    }
    th,
    td {
      padding: 16px;
      vertical-align: top;
      margin: 0;
      @media (min-width: 900px) {
        padding: 16px 8px;
        &:first-child {
          padding-left: 16px;
        }
        &:last-child {
          padding-right: 16px;
        }
      }

      span {
        margin: 0;
      }
      svg {
        height: 30px;
        width: 30px;
        @media (min-width: 900px) {
          height: 36px;
          width: 36px;
        }
      }
    }
    thead {
      background-color: $chambray;
      tr {
        th {
          @include fluid-type($min_viewport, $max_viewport, 18px, 28px);
          color: $warm-neutral;
          text-align: left;
          img {
            max-width: 300px;
            margin: 1em auto 1em 0;
            width: 100%;
          }
        }
      }
    }
    tbody {
      tr:nth-child(odd) {
        background-color: $warm-neutral;
      }
      tr td {
        &:first-child {
          font-weight: 700;
        }
      }
      tr {
        &.heading {
          background-color: $chambray;
          th,
          td {
            text-align: left;
            span {
              color: $warm-neutral;
            }
          }
        }
      }
    }
  }
}
