@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}

// fluid typography - https://codepen.io/mkmueller/pen/YzPPgJj
@mixin fluid-type($minViewport: 600px, $maxViewport: 1200px, $minFontSize: 16px, $maxFontSize: 20px) {
  $slope: math.div(($minFontSize - $maxFontSize), ($minViewport - $maxViewport));
  $base: $maxFontSize - $slope * $maxViewport;

  min-height: 0vw;
  font-size: $minFontSize;

  @media (min-width: $minViewport) {
    font-size: calc(#{$base} + 100vw * #{$slope});
  }

  @media (min-width: $maxViewport) {
    font-size: $maxFontSize;
  }
}
