.rb__block-four-up-icons {
  background-color: $warm-neutral;
  padding: $section-padding 0;
  @media (min-width: $tablet-width) {
    padding: $section-padding-large 0;
  }
  .contain {
    h2 {
      text-align: center;
    }
    .columns {
      display: grid;
      gap: 32px;
      grid-template-columns: 1fr 1fr;
      @media (min-width: 1000px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
      img {
        height: 80px;
        width: 80px;
        @media (min-width: 500px) {
          height: 120px;
          width: 120px;
        }
      }
    }
  }
}
