[class^="rb"] {
  h1,
  .h1,
  h1 a,
  .h1 a,
  h2,
  .h2,
  h2 a,
  .h2 a,
  h3,
  .h3,
  h3 a,
  .h3 a,
  h4,
  .h4,
  h4 a,
  .h4 a,
  h5,
  .h5,
  h5 a,
  .h5 a,
  h6,
  .h6,
  h6 a,
  .h6 a {
    font-family: Aileron, Sans-Serif;
    color: $affinity-blue;
    margin: 0 0 0.6em 0;
  }

  h1,
  .h1,
  h1 a,
  .h1 a {
    @include fluid-type($min_viewport, $max_viewport, 32px, 40px);
  }

  h2,
  .h2,
  h2 a,
  .h2 a {
    @include fluid-type($min_viewport, $max_viewport, 28px, 60px);
  }

  h3,
  .h3,
  h3 a,
  .h3 a {
    @include fluid-type($min_viewport, $max_viewport, 18px, 40px);
  }

  h4,
  .h4,
  h4 a,
  .h4 a {
    @include fluid-type($min_viewport, $max_viewport, 18px, 30px);
  }

  p,
  p span,
  cite,
  a,
  .wp-block-button__link,
  li,
  input,
  span,
  td,
  th,
  figcaption,
  cite {
    font-family: Aileron, Sans-Serif;
    @include fluid-type($min_viewport, $max_viewport, 18px, 20px);
    color: $text;
    line-height: 1.4;
    margin: 0 0 1em 0;
    &.small,
    &.small * {
      @include fluid-type($min_viewport, $max_viewport, 16px, 18px);
    }
    &.extra-small,
    &.extra-small * {
      @include fluid-type($min_viewport, $max_viewport, 14px, 14px);
    }
  }

  a {
    &:hover,
    &:focus {
      color: $chambray;
    }
  }

  a.external {
    svg.icon-external {
      fill: currentColor;
      height: 0.7em;
      margin: 0 0 2px 4px;
      transition: all 300ms ease;
      vertical-align: middle;
      width: 0.7em;
    }
  }

  ::selection {
    background: $sunset;
  }

  ul,
  ol {
    margin: 0 0 1rem 1em;
  }

  ol ol,
  ul ul,
  ol ul,
  ul ol {
    margin-bottom: 0;
  }

  li > ul,
  li > ol {
    margin-bottom: 0;
    margin-left: 1.5em;
  }

  .entry-content {
    ul,
    ol {
      margin-bottom: 16px;
      li {
        margin-bottom: 16px;
        ul,
        ol {
          margin-top: 16px;
          margin-left: 0;
        }
      }
    }
    > ul,
    > ol {
      margin-bottom: 40px;
    }

    ol li ol {
      list-style: lower-alpha;
    }

    ol li ol li ol {
      list-style: upper-roman;
    }
  }
}
