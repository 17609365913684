[class^=rb] {
  /* Text meant only for screen readers. */
  /* Do not show the outline on the skip link target. */
}
[class^=rb] .screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
}
[class^=rb] .screen-reader-text:focus {
  background-color: white;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  clip-path: none;
  color: black;
  display: block;
  font-size: 0.875rem;
  font-weight: 700;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
}
[class^=rb] #primary[tabindex="-1"]:focus {
  outline: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  margin: 0;
}

[class^=rb] img {
  display: block;
  height: auto;
  /* Make sure images are scaled correctly. */
  max-width: 100%;
  /* Adhere to container width. */
}
[class^=rb] .contain {
  margin-left: auto;
  margin-right: auto;
  max-width: 1920px;
  width: calc(100% - 32px);
}
@media (min-width: 450px) {
  [class^=rb] .contain {
    width: calc(100% - 72px);
  }
}
@media (min-width: 768px) {
  [class^=rb] .contain {
    width: calc(100% - 120px);
  }
}
[class^=rb] .contain h2 {
  text-align: center;
  max-width: 1200px;
  margin: 0 auto 1.4em;
}
[class^=rb] p,
[class^=rb] h1,
[class^=rb] h2,
[class^=rb] h3,
[class^=rb] h4,
[class^=rb] li,
[class^=rb] a.btn,
[class^=rb] img,
[class^=rb] tr,
[class^=rb] svg {
  transition: all 300ms ease;
  transform: translateY(2em);
  opacity: 0;
}
[class^=rb] p.animate,
[class^=rb] h1.animate,
[class^=rb] h2.animate,
[class^=rb] h3.animate,
[class^=rb] h4.animate,
[class^=rb] li.animate,
[class^=rb] a.btn.animate,
[class^=rb] img.animate,
[class^=rb] tr.animate,
[class^=rb] svg.animate {
  transform: translateY(0);
  opacity: 1;
}

[class^=rb] h1,
[class^=rb] .h1,
[class^=rb] h1 a,
[class^=rb] .h1 a,
[class^=rb] h2,
[class^=rb] .h2,
[class^=rb] h2 a,
[class^=rb] .h2 a,
[class^=rb] h3,
[class^=rb] .h3,
[class^=rb] h3 a,
[class^=rb] .h3 a,
[class^=rb] h4,
[class^=rb] .h4,
[class^=rb] h4 a,
[class^=rb] .h4 a,
[class^=rb] h5,
[class^=rb] .h5,
[class^=rb] h5 a,
[class^=rb] .h5 a,
[class^=rb] h6,
[class^=rb] .h6,
[class^=rb] h6 a,
[class^=rb] .h6 a {
  font-family: Aileron, Sans-Serif;
  color: #002855;
  margin: 0 0 0.6em 0;
}
[class^=rb] h1,
[class^=rb] .h1,
[class^=rb] h1 a,
[class^=rb] .h1 a {
  min-height: 0vw;
  font-size: 32px;
}
@media (min-width: 320px) {
  [class^=rb] h1,
[class^=rb] .h1,
[class^=rb] h1 a,
[class^=rb] .h1 a {
    font-size: calc(30.4px + 100vw * 0.005);
  }
}
@media (min-width: 1920px) {
  [class^=rb] h1,
[class^=rb] .h1,
[class^=rb] h1 a,
[class^=rb] .h1 a {
    font-size: 40px;
  }
}
[class^=rb] h2,
[class^=rb] .h2,
[class^=rb] h2 a,
[class^=rb] .h2 a {
  min-height: 0vw;
  font-size: 28px;
}
@media (min-width: 320px) {
  [class^=rb] h2,
[class^=rb] .h2,
[class^=rb] h2 a,
[class^=rb] .h2 a {
    font-size: calc(21.6px + 100vw * 0.02);
  }
}
@media (min-width: 1920px) {
  [class^=rb] h2,
[class^=rb] .h2,
[class^=rb] h2 a,
[class^=rb] .h2 a {
    font-size: 60px;
  }
}
[class^=rb] h3,
[class^=rb] .h3,
[class^=rb] h3 a,
[class^=rb] .h3 a {
  min-height: 0vw;
  font-size: 18px;
}
@media (min-width: 320px) {
  [class^=rb] h3,
[class^=rb] .h3,
[class^=rb] h3 a,
[class^=rb] .h3 a {
    font-size: calc(13.6px + 100vw * 0.01375);
  }
}
@media (min-width: 1920px) {
  [class^=rb] h3,
[class^=rb] .h3,
[class^=rb] h3 a,
[class^=rb] .h3 a {
    font-size: 40px;
  }
}
[class^=rb] h4,
[class^=rb] .h4,
[class^=rb] h4 a,
[class^=rb] .h4 a {
  min-height: 0vw;
  font-size: 18px;
}
@media (min-width: 320px) {
  [class^=rb] h4,
[class^=rb] .h4,
[class^=rb] h4 a,
[class^=rb] .h4 a {
    font-size: calc(15.6px + 100vw * 0.0075);
  }
}
@media (min-width: 1920px) {
  [class^=rb] h4,
[class^=rb] .h4,
[class^=rb] h4 a,
[class^=rb] .h4 a {
    font-size: 30px;
  }
}
[class^=rb] p,
[class^=rb] p span,
[class^=rb] cite,
[class^=rb] a,
[class^=rb] .wp-block-button__link,
[class^=rb] li,
[class^=rb] input,
[class^=rb] span,
[class^=rb] td,
[class^=rb] th,
[class^=rb] figcaption,
[class^=rb] cite {
  font-family: Aileron, Sans-Serif;
  min-height: 0vw;
  font-size: 18px;
  color: #2f2f2f;
  line-height: 1.4;
  margin: 0 0 1em 0;
}
@media (min-width: 320px) {
  [class^=rb] p,
[class^=rb] p span,
[class^=rb] cite,
[class^=rb] a,
[class^=rb] .wp-block-button__link,
[class^=rb] li,
[class^=rb] input,
[class^=rb] span,
[class^=rb] td,
[class^=rb] th,
[class^=rb] figcaption,
[class^=rb] cite {
    font-size: calc(17.6px + 100vw * 0.00125);
  }
}
@media (min-width: 1920px) {
  [class^=rb] p,
[class^=rb] p span,
[class^=rb] cite,
[class^=rb] a,
[class^=rb] .wp-block-button__link,
[class^=rb] li,
[class^=rb] input,
[class^=rb] span,
[class^=rb] td,
[class^=rb] th,
[class^=rb] figcaption,
[class^=rb] cite {
    font-size: 20px;
  }
}
[class^=rb] p.small, [class^=rb] p.small *,
[class^=rb] p span.small,
[class^=rb] p span.small *,
[class^=rb] cite.small,
[class^=rb] cite.small *,
[class^=rb] a.small,
[class^=rb] a.small *,
[class^=rb] .wp-block-button__link.small,
[class^=rb] .wp-block-button__link.small *,
[class^=rb] li.small,
[class^=rb] li.small *,
[class^=rb] input.small,
[class^=rb] input.small *,
[class^=rb] span.small,
[class^=rb] span.small *,
[class^=rb] td.small,
[class^=rb] td.small *,
[class^=rb] th.small,
[class^=rb] th.small *,
[class^=rb] figcaption.small,
[class^=rb] figcaption.small *,
[class^=rb] cite.small,
[class^=rb] cite.small * {
  min-height: 0vw;
  font-size: 16px;
}
@media (min-width: 320px) {
  [class^=rb] p.small, [class^=rb] p.small *,
[class^=rb] p span.small,
[class^=rb] p span.small *,
[class^=rb] cite.small,
[class^=rb] cite.small *,
[class^=rb] a.small,
[class^=rb] a.small *,
[class^=rb] .wp-block-button__link.small,
[class^=rb] .wp-block-button__link.small *,
[class^=rb] li.small,
[class^=rb] li.small *,
[class^=rb] input.small,
[class^=rb] input.small *,
[class^=rb] span.small,
[class^=rb] span.small *,
[class^=rb] td.small,
[class^=rb] td.small *,
[class^=rb] th.small,
[class^=rb] th.small *,
[class^=rb] figcaption.small,
[class^=rb] figcaption.small *,
[class^=rb] cite.small,
[class^=rb] cite.small * {
    font-size: calc(15.6px + 100vw * 0.00125);
  }
}
@media (min-width: 1920px) {
  [class^=rb] p.small, [class^=rb] p.small *,
[class^=rb] p span.small,
[class^=rb] p span.small *,
[class^=rb] cite.small,
[class^=rb] cite.small *,
[class^=rb] a.small,
[class^=rb] a.small *,
[class^=rb] .wp-block-button__link.small,
[class^=rb] .wp-block-button__link.small *,
[class^=rb] li.small,
[class^=rb] li.small *,
[class^=rb] input.small,
[class^=rb] input.small *,
[class^=rb] span.small,
[class^=rb] span.small *,
[class^=rb] td.small,
[class^=rb] td.small *,
[class^=rb] th.small,
[class^=rb] th.small *,
[class^=rb] figcaption.small,
[class^=rb] figcaption.small *,
[class^=rb] cite.small,
[class^=rb] cite.small * {
    font-size: 18px;
  }
}
[class^=rb] p.extra-small, [class^=rb] p.extra-small *,
[class^=rb] p span.extra-small,
[class^=rb] p span.extra-small *,
[class^=rb] cite.extra-small,
[class^=rb] cite.extra-small *,
[class^=rb] a.extra-small,
[class^=rb] a.extra-small *,
[class^=rb] .wp-block-button__link.extra-small,
[class^=rb] .wp-block-button__link.extra-small *,
[class^=rb] li.extra-small,
[class^=rb] li.extra-small *,
[class^=rb] input.extra-small,
[class^=rb] input.extra-small *,
[class^=rb] span.extra-small,
[class^=rb] span.extra-small *,
[class^=rb] td.extra-small,
[class^=rb] td.extra-small *,
[class^=rb] th.extra-small,
[class^=rb] th.extra-small *,
[class^=rb] figcaption.extra-small,
[class^=rb] figcaption.extra-small *,
[class^=rb] cite.extra-small,
[class^=rb] cite.extra-small * {
  min-height: 0vw;
  font-size: 14px;
}
@media (min-width: 320px) {
  [class^=rb] p.extra-small, [class^=rb] p.extra-small *,
[class^=rb] p span.extra-small,
[class^=rb] p span.extra-small *,
[class^=rb] cite.extra-small,
[class^=rb] cite.extra-small *,
[class^=rb] a.extra-small,
[class^=rb] a.extra-small *,
[class^=rb] .wp-block-button__link.extra-small,
[class^=rb] .wp-block-button__link.extra-small *,
[class^=rb] li.extra-small,
[class^=rb] li.extra-small *,
[class^=rb] input.extra-small,
[class^=rb] input.extra-small *,
[class^=rb] span.extra-small,
[class^=rb] span.extra-small *,
[class^=rb] td.extra-small,
[class^=rb] td.extra-small *,
[class^=rb] th.extra-small,
[class^=rb] th.extra-small *,
[class^=rb] figcaption.extra-small,
[class^=rb] figcaption.extra-small *,
[class^=rb] cite.extra-small,
[class^=rb] cite.extra-small * {
    font-size: calc(14px + 100vw * 0);
  }
}
@media (min-width: 1920px) {
  [class^=rb] p.extra-small, [class^=rb] p.extra-small *,
[class^=rb] p span.extra-small,
[class^=rb] p span.extra-small *,
[class^=rb] cite.extra-small,
[class^=rb] cite.extra-small *,
[class^=rb] a.extra-small,
[class^=rb] a.extra-small *,
[class^=rb] .wp-block-button__link.extra-small,
[class^=rb] .wp-block-button__link.extra-small *,
[class^=rb] li.extra-small,
[class^=rb] li.extra-small *,
[class^=rb] input.extra-small,
[class^=rb] input.extra-small *,
[class^=rb] span.extra-small,
[class^=rb] span.extra-small *,
[class^=rb] td.extra-small,
[class^=rb] td.extra-small *,
[class^=rb] th.extra-small,
[class^=rb] th.extra-small *,
[class^=rb] figcaption.extra-small,
[class^=rb] figcaption.extra-small *,
[class^=rb] cite.extra-small,
[class^=rb] cite.extra-small * {
    font-size: 14px;
  }
}
[class^=rb] a:hover, [class^=rb] a:focus {
  color: #3b429f;
}
[class^=rb] a.external svg.icon-external {
  fill: currentColor;
  height: 0.7em;
  margin: 0 0 2px 4px;
  transition: all 300ms ease;
  vertical-align: middle;
  width: 0.7em;
}
[class^=rb] ::selection {
  background: #ffb05e;
}
[class^=rb] ul,
[class^=rb] ol {
  margin: 0 0 1rem 1em;
}
[class^=rb] ol ol,
[class^=rb] ul ul,
[class^=rb] ol ul,
[class^=rb] ul ol {
  margin-bottom: 0;
}
[class^=rb] li > ul,
[class^=rb] li > ol {
  margin-bottom: 0;
  margin-left: 1.5em;
}
[class^=rb] .entry-content ul,
[class^=rb] .entry-content ol {
  margin-bottom: 16px;
}
[class^=rb] .entry-content ul li,
[class^=rb] .entry-content ol li {
  margin-bottom: 16px;
}
[class^=rb] .entry-content ul li ul,
[class^=rb] .entry-content ul li ol,
[class^=rb] .entry-content ol li ul,
[class^=rb] .entry-content ol li ol {
  margin-top: 16px;
  margin-left: 0;
}
[class^=rb] .entry-content > ul,
[class^=rb] .entry-content > ol {
  margin-bottom: 40px;
}
[class^=rb] .entry-content ol li ol {
  list-style: lower-alpha;
}
[class^=rb] .entry-content ol li ol li ol {
  list-style: upper-roman;
}

[class^=rb] .btn {
  background-color: #002855;
  border-radius: 6px;
  color: white;
  display: inline-block;
  padding: 0.6em 1.2em;
  text-align: center;
  text-decoration: none;
  transition: all 300ms ease;
}
@media (max-width: 550px) {
  [class^=rb] .btn {
    width: 100%;
  }
}
[class^=rb] .btn:hover, [class^=rb] .btn:active, [class^=rb] .btn:focus {
  background-color: #95d600;
  color: #002855;
}

.rb__block-videos {
  margin: 120px 0;
}
.rb__block-videos .contain > h2,
.rb__block-videos .contain > p {
  text-align: center;
}
.rb__block-videos .contain > h2 {
  margin-bottom: 16px;
}
.rb__block-videos .contain > p {
  margin-bottom: 80px;
}
.rb__block-videos .contain .videos__full {
  aspect-ratio: 16/9;
  margin-bottom: 30px;
}
.rb__block-videos .contain .videos__grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 30px;
}
@media (min-width: 1080px) {
  .rb__block-videos .contain .videos__grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
.rb__block-videos .contain .videos__grid .grid__single {
  aspect-ratio: 16/9;
}
.rb__block-videos .contain .videos__grid .grid__single div {
  margin-bottom: 12px;
}
.rb__block-videos .contain .videos__grid .grid__single p {
  text-align: left;
}

.rb__block-stats {
  background-color: #f2f0eb;
  padding: 120px 0;
}
.rb__block-stats .contain h2,
.rb__block-stats .contain h3,
.rb__block-stats .contain p {
  text-align: center;
}
.rb__block-stats .contain h2 {
  margin-bottom: 16px;
}
.rb__block-stats .contain > p {
  margin-bottom: 80px;
}
.rb__block-stats .contain h3,
.rb__block-stats .contain h3 span {
  min-height: 0vw;
  font-size: 32px;
  color: #3b429f;
  margin-bottom: 20px;
}
@media (min-width: 320px) {
  .rb__block-stats .contain h3,
.rb__block-stats .contain h3 span {
    font-size: calc(25.6px + 100vw * 0.02);
  }
}
@media (min-width: 1920px) {
  .rb__block-stats .contain h3,
.rb__block-stats .contain h3 span {
    font-size: 64px;
  }
}
.rb__block-stats .contain .stats {
  display: grid;
  gap: 60px;
  grid-template-columns: repeat(1, 1fr);
}
@media (min-width: 550px) {
  .rb__block-stats .contain .stats {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1080px) {
  .rb__block-stats .contain .stats {
    grid-template-columns: repeat(4, 1fr);
  }
}
.rb__block-stats .contain .stats .stats__single p {
  margin-bottom: 0;
}

.rb__block-banner {
  margin-bottom: 3em;
}
.rb__block-banner .contain {
  background-color: #3b429f;
  padding: 4em 0 0;
  position: relative;
  overflow: hidden;
}
@media (min-width: 1300px) {
  .rb__block-banner .contain {
    padding: 4em 3em 0;
  }
}
.rb__block-banner .contain:after {
  background-image: url("../images/Banner-Arrows.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  height: 110%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 110%;
}
.rb__block-banner .contain .content-wrapper {
  position: relative;
  z-index: 1;
}
@media (max-width: 1299px) {
  .rb__block-banner .contain .content-wrapper {
    display: flex;
    flex-direction: column-reverse;
  }
}
@media (min-width: 1300px) {
  .rb__block-banner .contain .content-wrapper {
    gap: 1em;
    align-items: flex-end;
    display: grid;
    grid-template-columns: 45% 55%;
  }
}
@media (min-width: 1550px) {
  .rb__block-banner .contain .content-wrapper {
    grid-template-columns: 35% 65%;
  }
}
.rb__block-banner .contain .content-wrapper .content {
  background-color: white;
  padding: 1em;
  position: relative;
  z-index: 2;
}
@media (min-width: 768px) {
  .rb__block-banner .contain .content-wrapper .content {
    padding: 2em;
  }
}
@media (min-width: 1300px) {
  .rb__block-banner .contain .content-wrapper .content {
    margin-bottom: 4em;
  }
}
.rb__block-banner .contain .content-wrapper .image {
  margin-bottom: -20%;
  position: relative;
  z-index: 1;
}
@media (max-width: 1299px) {
  .rb__block-banner .contain .content-wrapper .image {
    padding: 0 2em;
  }
}
@media (max-width: 700px) {
  .rb__block-banner .contain .content-wrapper .image {
    padding: 0 1em;
  }
}
@media (min-width: 1550px) {
  .rb__block-banner .contain .content-wrapper .image {
    margin-bottom: -20%;
  }
}
@media (min-width: 1700px) {
  .rb__block-banner .contain .content-wrapper .image {
    margin-bottom: -30%;
  }
}
.rb__block-banner .contain .content-wrapper .image img {
  max-width: 100%;
}

.rb__block-banner.orange .contain {
  background-color: #ffb05e;
  padding-top: 0;
}
.rb__block-banner.orange .contain:after {
  display: none;
}
.rb__block-banner.orange .contain .content-wrapper {
  grid-template-columns: 55% 45%;
}
@media (min-width: 1300px) {
  .rb__block-banner.orange .contain .content-wrapper .content {
    grid-row-start: 1;
    grid-column-start: 2;
  }
  .rb__block-banner.orange .contain .content-wrapper .image {
    grid-row-start: 1;
    grid-column-start: 1;
  }
}
@media (min-width: 1550px) {
  .rb__block-banner.orange .contain .content-wrapper {
    grid-template-columns: 65% 35%;
  }
}
.rb__block-banner.orange .contain .content-wrapper .image {
  margin-bottom: 0;
}

.rb__block-banner-cards .contain img.desktop,
.rb__block-banner-cards .contain img.tablet {
  display: none;
}
.rb__block-banner-cards .contain img.mobile {
  display: block;
}
@media (min-width: 405px) {
  .rb__block-banner-cards .contain img.tablet {
    display: block;
  }
  .rb__block-banner-cards .contain img.mobile,
.rb__block-banner-cards .contain img.desktop {
    display: none;
  }
}
@media (min-width: 600px) {
  .rb__block-banner-cards .contain img.desktop {
    display: block;
  }
  .rb__block-banner-cards .contain img.mobile,
.rb__block-banner-cards .contain img.tablet {
    display: none;
  }
}

.rb__block-four-up-cta {
  margin: 60px 0;
}
@media (min-width: 768px) {
  .rb__block-four-up-cta {
    margin: 120px 0;
  }
}
.rb__block-four-up-cta h2 {
  text-align: center;
}
.rb__block-four-up-cta .contain .ctas {
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
}
@media (min-width: 700px) {
  .rb__block-four-up-cta .contain .ctas {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 1300px) {
  .rb__block-four-up-cta .contain .ctas {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
.rb__block-four-up-cta .contain .ctas a.cta {
  align-items: center;
  border-bottom: 1px solid #002855;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
  padding: 0 0 16px;
  text-decoration: none;
  transition: all 300ms ease;
}
@media (min-width: 500px) {
  .rb__block-four-up-cta .contain .ctas a.cta {
    padding: 24px;
    border: 1px solid #002855;
    border-radius: 10px;
  }
  .rb__block-four-up-cta .contain .ctas a.cta:hover {
    transform: scale(1.03);
    box-shadow: 0px 0px 40px 10px rgba(0, 0, 0, 0.1);
    border-color: transparent;
  }
}
.rb__block-four-up-cta .contain .ctas a.cta .content img {
  height: 80px;
  width: 80px;
  margin-bottom: 8px;
}
@media (min-width: 500px) {
  .rb__block-four-up-cta .contain .ctas a.cta .content img {
    height: 120px;
    width: 120px;
  }
}
.rb__block-four-up-cta .contain .ctas a.cta .content p {
  margin-bottom: 0;
}
.rb__block-four-up-cta .contain .ctas a.cta svg {
  flex-shrink: 0;
}

.rb__block-two-up-cta {
  margin: 60px 0;
}
@media (min-width: 768px) {
  .rb__block-two-up-cta {
    margin: 120px 0;
  }
}
.rb__block-two-up-cta .contain {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
@media (max-width: 600px) {
  .rb__block-two-up-cta .contain {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .rb__block-two-up-cta .contain {
    gap: 32px;
    flex-direction: row;
  }
}
.rb__block-two-up-cta .contain .card {
  background-color: #f2f0eb;
  display: block;
  flex-basis: 100%;
  margin: 0;
  text-decoration: none;
  transition: all 300ms ease;
}
.rb__block-two-up-cta .contain .card:hover {
  transform: scale(1.02);
  box-shadow: 0px 0px 40px 10px rgba(0, 0, 0, 0.1);
}
.rb__block-two-up-cta .contain .card:hover .image .wave-wrapper .svg-wrapper svg {
  animation-play-state: running;
}
.rb__block-two-up-cta .contain .card .image {
  position: relative;
  overflow: hidden;
}
.rb__block-two-up-cta .contain .card .image .wave-wrapper {
  bottom: -10%;
  left: 0;
  position: absolute;
  width: 100%;
}
.rb__block-two-up-cta .contain .card .image .wave-wrapper .svg-wrapper {
  display: flex;
}
.rb__block-two-up-cta .contain .card .image .wave-wrapper .svg-wrapper svg {
  flex-shrink: 0;
  animation: text-scroll 5s linear infinite;
  animation-play-state: paused;
}
.rb__block-two-up-cta .contain .card .image .wave-wrapper .svg-wrapper svg:nth-child(n+2) {
  margin-left: -0.1em;
}
.rb__block-two-up-cta .contain .card .content {
  padding: 2em;
}

@keyframes text-scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
.rb__block-basic-content {
  margin: 60px 0;
}
@media (min-width: 768px) {
  .rb__block-basic-content {
    margin: 120px 0;
  }
}
.rb__block-basic-content .contain {
  max-width: 900px;
}
.rb__block-basic-content .contain p {
  min-height: 0vw;
  font-size: 18px;
}
@media (min-width: 320px) {
  .rb__block-basic-content .contain p {
    font-size: calc(16.8px + 100vw * 0.00375);
  }
}
@media (min-width: 1920px) {
  .rb__block-basic-content .contain p {
    font-size: 24px;
  }
}

.rb__block-four-up-icons {
  background-color: #f2f0eb;
  padding: 60px 0;
}
@media (min-width: 768px) {
  .rb__block-four-up-icons {
    padding: 120px 0;
  }
}
.rb__block-four-up-icons .contain h2 {
  text-align: center;
}
.rb__block-four-up-icons .contain .columns {
  display: grid;
  gap: 32px;
  grid-template-columns: 1fr 1fr;
}
@media (min-width: 1000px) {
  .rb__block-four-up-icons .contain .columns {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
.rb__block-four-up-icons .contain .columns img {
  height: 80px;
  width: 80px;
}
@media (min-width: 500px) {
  .rb__block-four-up-icons .contain .columns img {
    height: 120px;
    width: 120px;
  }
}

.rb__block-image-content {
  margin: 60px 0;
}
@media (min-width: 768px) {
  .rb__block-image-content {
    margin: 120px 0;
  }
}
.rb__block-image-content.beige {
  background-color: #f2f0eb;
  margin: 0;
  padding: 60px 0;
}
@media (min-width: 768px) {
  .rb__block-image-content.beige {
    padding: 120px 0;
  }
}
@media (min-width: 1000px) {
  .rb__block-image-content.beige .contain .content-wrapper {
    flex-direction: row-reverse;
  }
}
.rb__block-image-content .contain > img {
  max-width: 550px;
  margin: 0 auto 3em;
}
.rb__block-image-content .contain .content-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column-reverse;
  gap: 32px;
}
@media (min-width: 1000px) {
  .rb__block-image-content .contain .content-wrapper {
    gap: 40px;
    flex-direction: row;
  }
}
.rb__block-image-content .contain .content-wrapper .left,
.rb__block-image-content .contain .content-wrapper .right {
  flex-basis: 100%;
}
.rb__block-image-content .contain .content-wrapper .left img,
.rb__block-image-content .contain .content-wrapper .right img {
  height: auto;
  width: 100%;
}
.rb__block-image-content .contain .content-wrapper .left ul.checkmarks li,
.rb__block-image-content .contain .content-wrapper .right ul.checkmarks li {
  list-style-type: none;
  padding-left: 8px;
  position: relative;
  margin-bottom: 24px;
}
.rb__block-image-content .contain .content-wrapper .left ul.checkmarks li:before,
.rb__block-image-content .contain .content-wrapper .right ul.checkmarks li:before {
  background-image: url("data:image/svg+xml,%3Csvg width='37' height='38' viewBox='0 0 37 38' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.14 0.985352C8.14 0.985352 0 9.12535 0 19.1254C0 29.1254 8.14 37.2654 18.14 37.2654C28.14 37.2654 36.28 29.1254 36.28 19.1254C36.28 9.12535 28.14 0.985352 18.14 0.985352ZM27.44 14.1254L15.68 25.8854C15.44 26.1254 15.12 26.2454 14.78 26.2454C14.44 26.2454 14.14 26.1254 13.88 25.8854L8.84 20.8454C8.34 20.3454 8.34 19.5654 8.84 19.0654C9.34 18.5654 10.14 18.5654 10.62 19.0654L14.76 23.2054L25.62 12.3454C26.12 11.8454 26.92 11.8454 27.4 12.3454C27.88 12.8454 27.9 13.6254 27.4 14.1254H27.44Z' fill='%233D479C'/%3E%3C/svg%3E%0A");
  background-size: 100%;
  content: "";
  height: 1.5em;
  width: 1.5em;
  display: block;
  position: absolute;
  left: calc(-1.5em - 8px);
  top: 0;
}
.rb__block-image-content .contain .content-wrapper .left ul.checkmarks li::marker,
.rb__block-image-content .contain .content-wrapper .right ul.checkmarks li::marker {
  content: "";
  display: none;
}

.rb__rewards-calculator {
  margin: 60px 0;
}
@media (min-width: 768px) {
  .rb__rewards-calculator {
    margin: 120px 0;
  }
}
.rb__rewards-calculator .contain h2 {
  text-align: center;
}

.rb__block-column-content {
  background-color: #3b429f;
  padding: 60px 0;
}
@media (min-width: 768px) {
  .rb__block-column-content {
    padding: 120px 0;
  }
}
.rb__block-column-content .contain h2,
.rb__block-column-content .contain h4,
.rb__block-column-content .contain p {
  color: #f2f0eb;
}
.rb__block-column-content .contain .columns .row {
  gap: 40px;
  margin-bottom: 40px;
  padding-bottom: 24px;
}
@media (min-width: 880px) {
  .rb__block-column-content .contain .columns .row {
    display: flex;
    gap: 64px;
  }
}
.rb__block-column-content .contain .columns .row:last-child {
  margin-bottom: 0;
}
.rb__block-column-content .contain .columns .row.border {
  border-bottom: 1px solid #f2f0eb;
}
.rb__block-column-content .contain .columns .row .col.small {
  flex-basis: 25%;
  flex-shrink: 0;
}

.rb__block-table {
  margin: 60px 0;
}
@media (min-width: 768px) {
  .rb__block-table {
    margin: 120px 0;
  }
}
.rb__block-table table {
  border: none;
  border-spacing: 0;
}
@media (min-width: 900px) {
  .rb__block-table table.four-col th,
.rb__block-table table.four-col td {
    width: calc(100% / 4);
  }
}
@media (min-width: 900px) {
  .rb__block-table table.three-col th,
.rb__block-table table.three-col td {
    width: calc(100% / 3);
  }
}
.rb__block-table table.bt tbody tr td:before, .rb__block-table table.bt tfoot tr td:before {
  color: #002855;
  width: 8em;
  padding-right: 16px;
}
.rb__block-table table.bt tbody tr td.no-th:before, .rb__block-table table.bt tfoot tr td.no-th:before {
  display: none;
}
.rb__block-table table.bt tbody tr td.no-th span, .rb__block-table table.bt tfoot tr td.no-th span {
  font-size: 20px;
}
.rb__block-table table th,
.rb__block-table table td {
  padding: 16px;
  vertical-align: top;
  margin: 0;
}
@media (min-width: 900px) {
  .rb__block-table table th,
.rb__block-table table td {
    padding: 16px 8px;
  }
  .rb__block-table table th:first-child,
.rb__block-table table td:first-child {
    padding-left: 16px;
  }
  .rb__block-table table th:last-child,
.rb__block-table table td:last-child {
    padding-right: 16px;
  }
}
.rb__block-table table th span,
.rb__block-table table td span {
  margin: 0;
}
.rb__block-table table th svg,
.rb__block-table table td svg {
  height: 30px;
  width: 30px;
}
@media (min-width: 900px) {
  .rb__block-table table th svg,
.rb__block-table table td svg {
    height: 36px;
    width: 36px;
  }
}
.rb__block-table table thead {
  background-color: #3b429f;
}
.rb__block-table table thead tr th {
  min-height: 0vw;
  font-size: 18px;
  color: #f2f0eb;
  text-align: left;
}
@media (min-width: 320px) {
  .rb__block-table table thead tr th {
    font-size: calc(16px + 100vw * 0.00625);
  }
}
@media (min-width: 1920px) {
  .rb__block-table table thead tr th {
    font-size: 28px;
  }
}
.rb__block-table table thead tr th img {
  max-width: 300px;
  margin: 1em auto 1em 0;
  width: 100%;
}
.rb__block-table table tbody tr:nth-child(odd) {
  background-color: #f2f0eb;
}
.rb__block-table table tbody tr td:first-child {
  font-weight: 700;
}
.rb__block-table table tbody tr.heading {
  background-color: #3b429f;
}
.rb__block-table table tbody tr.heading th,
.rb__block-table table tbody tr.heading td {
  text-align: left;
}
.rb__block-table table tbody tr.heading th span,
.rb__block-table table tbody tr.heading td span {
  color: #f2f0eb;
}