.rb__block-basic-content {
  margin: $section-margin 0;
  @media (min-width: $tablet-width) {
    margin: $section-margin-large 0;
  }
  .contain {
    max-width: 900px;
    p {
      @include fluid-type($min_viewport, $max_viewport, 18px, 24px);
    }
  }
}
