.rb__block-videos {
  margin: 120px 0;
  .contain {
    > h2,
    > p {
      text-align: center;
    }
    > h2 {
      margin-bottom: 16px;
    }
    > p {
      margin-bottom: 80px;
    }
    .videos__full {
      aspect-ratio: 16/9;
      margin-bottom: 30px;
    }
    .videos__grid {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 30px;
      @media (min-width: 1080px) {
        grid-template-columns: repeat(3, 1fr);
      }
      .grid__single {
        aspect-ratio: 16/9;
        div {
          margin-bottom: 12px;
        }
        p {
          text-align: left;
        }
      }
    }
  }
}
