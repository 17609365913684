.rb__block-stats {
  background-color: $warm-neutral;
  padding: 120px 0;
  .contain {
    h2,
    h3,
    p {
      text-align: center;
    }
    h2 {
      margin-bottom: 16px;
    }
    > p {
      margin-bottom: 80px;
    }
    h3,
    h3 span {
      @include fluid-type($min_viewport, $max_viewport, 32px, 64px);
      color: $chambray;
      margin-bottom: 20px;
    }
    .stats {
      display: grid;
      gap: 60px;
      grid-template-columns: repeat(1, 1fr);
      @media (min-width: 550px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media (min-width: 1080px) {
        grid-template-columns: repeat(4, 1fr);
      }
      .stats__single {
        p {
          margin-bottom: 0;
        }
      }
    }
  }
}
