.rb__block-four-up-cta {
  margin: $section-margin 0;
  @media (min-width: $tablet-width) {
    margin: $section-margin-large 0;
  }
  h2 {
    text-align: center;
  }
  .contain .ctas {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
    @media (min-width: 700px) {
      grid-template-columns: 1fr 1fr;
    }
    @media (min-width: 1300px) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    a.cta {
      align-items: center;
      border-bottom: 1px solid $affinity-blue;
      display: flex;
      justify-content: space-between;
      margin-bottom: 0;
      padding: 0 0 16px;
      text-decoration: none;
      transition: all 300ms ease;
      @media (min-width: 500px) {
        padding: 24px;
        border: 1px solid $affinity-blue;
        border-radius: 10px;
        &:hover {
          transform: scale(1.03);
          box-shadow: 0px 0px 40px 10px rgba(0, 0, 0, 0.1);
          border-color: transparent;
        }
      }
      .content {
        img {
          height: 80px;
          width: 80px;
          margin-bottom: 8px;
          @media (min-width: 500px) {
            height: 120px;
            width: 120px;
          }
        }
        p {
          margin-bottom: 0;
        }
      }
      svg {
        flex-shrink: 0;
      }
    }
  }
}
