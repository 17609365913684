// Colours
$affinity-blue: #002855;
$terracotta: #ea6a5a;
$green: #95d600;
$chambray: #3b429f;
$sunset: #ffb05e;
$warm-neutral: #f2f0eb;

$text: #2f2f2f;

$curve: cubic-bezier(0.76, 0.25, 0.38, 0.99);

// Spacing
$spacing-modifier: 8px;

$section-margin: 60px;
$section-padding: $section-margin;
$section-margin-large: 120px;
$section-padding-large: $section-margin-large;

$content-width: 1920px;
$tablet-width: 768px;
$desktop-width: 1440px;
$sidebar-nav-breakpoint: 1200px;

$min-viewport: 320px;
$max-viewport: $content-width;

$base-font-size: 18px;

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  margin: 0;
}

[class^="rb"] {
  img {
    display: block;
    height: auto; /* Make sure images are scaled correctly. */
    max-width: 100%; /* Adhere to container width. */
  }

  .contain {
    margin-left: auto;
    margin-right: auto;
    max-width: $content-width;
    width: calc(100% - 32px);
    @media (min-width: 450px) {
      width: calc(100% - 72px);
    }
    @media (min-width: $tablet-width) {
      width: calc(100% - 120px);
    }
    h2 {
      text-align: center;
      max-width: 1200px;
      margin: 0 auto 1.4em;
    }
  }

  p,
  h1,
  h2,
  h3,
  h4,
  li,
  a.btn,
  img,
  tr,
  svg {
    transition: all 300ms ease;
    transform: translateY(2em);
    opacity: 0;
    &.animate {
      transform: translateY(0);
      opacity: 1;
    }
  }
}
