.rb__block-two-up-cta {
  margin: $section-margin 0;
  @media (min-width: $tablet-width) {
    margin: $section-margin-large 0;
  }
  .contain {
    display: flex;
    flex-direction: column;
    gap: 24px;
    @media (max-width: 600px) {
      width: 100%;
    }
    @media (min-width: $tablet-width) {
      gap: 32px;
      flex-direction: row;
    }
    .card {
      background-color: $warm-neutral;
      display: block;
      flex-basis: 100%;
      margin: 0;
      text-decoration: none;
      transition: all 300ms ease;
      &:hover {
        transform: scale(1.02);
        box-shadow: 0px 0px 40px 10px rgba(0, 0, 0, 0.1);
        .image .wave-wrapper .svg-wrapper svg {
          animation-play-state: running;
        }
      }
      .image {
        position: relative;
        overflow: hidden;
        .wave-wrapper {
          bottom: -10%;
          left: 0;
          position: absolute;
          width: 100%;
          .svg-wrapper {
            display: flex;
            svg {
              flex-shrink: 0;
              animation: text-scroll 5s linear infinite;
              animation-play-state: paused;
              &:nth-child(n + 2) {
                margin-left: -0.1em;
              }
            }
          }
        }
      }
      .content {
        padding: 2em;
      }
    }
  }
}
@keyframes text-scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
