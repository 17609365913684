.rb__block-banner {
  margin-bottom: 3em;
  .contain {
    background-color: $chambray;
    padding: 4em 0 0;
    position: relative;
    overflow: hidden;
    @media (min-width: 1300px) {
      padding: 4em 3em 0;
    }
    &:after {
      background-image: url("../images/Banner-Arrows.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      content: "";
      height: 110%;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 110%;
    }
    .content-wrapper {
      position: relative;
      z-index: 1;
      @media (max-width: 1299px) {
        display: flex;
        flex-direction: column-reverse;
      }
      @media (min-width: 1300px) {
        gap: 1em;
        align-items: flex-end;
        display: grid;
        grid-template-columns: 45% 55%;
      }
      @media (min-width: 1550px) {
        grid-template-columns: 35% 65%;
      }
      .content {
        background-color: white;
        padding: 1em;
        position: relative;
        z-index: 2;
        @media (min-width: $tablet-width) {
          padding: 2em;
        }
        @media (min-width: 1300px) {
          margin-bottom: 4em;
        }
      }
      .image {
        margin-bottom: -20%;
        position: relative;
        z-index: 1;
        @media (max-width: 1299px) {
          padding: 0 2em;
        }
        @media (max-width: 700px) {
          padding: 0 1em;
        }
        @media (min-width: 1550px) {
          margin-bottom: -20%;
        }
        @media (min-width: 1700px) {
          margin-bottom: -30%;
        }
        img {
          max-width: 100%;
        }
      }
    }
  }
}

.rb__block-banner.orange {
  .contain {
    background-color: $sunset;
    padding-top: 0;
    &:after {
      display: none;
    }
    .content-wrapper {
      grid-template-columns: 55% 45%;
      @media (min-width: 1300px) {
        .content {
          grid-row-start: 1;
          grid-column-start: 2;
        }
        .image {
          grid-row-start: 1;
          grid-column-start: 1;
        }
      }
      @media (min-width: 1550px) {
        grid-template-columns: 65% 35%;
      }
      .image {
        margin-bottom: 0;
      }
    }
  }
}

.rb__block-banner-cards {
  .contain {
    img.desktop,
    img.tablet {
      display: none;
    }
    img.mobile {
      display: block;
    }
    @media (min-width: 405px) {
      img.tablet {
        display: block;
      }
      img.mobile,
      img.desktop {
        display: none;
      }
    }
    @media (min-width: 600px) {
      img.desktop {
        display: block;
      }
      img.mobile,
      img.tablet {
        display: none;
      }
    }
  }
}
