.rb__block-column-content {
  background-color: $chambray;
  padding: $section-padding 0;
  @media (min-width: $tablet-width) {
    padding: $section-padding-large 0;
  }
  .contain {
    h2,
    h4,
    p {
      color: $warm-neutral;
    }
    .columns {
      .row {
        gap: 40px;
        margin-bottom: 40px;
        padding-bottom: 24px;
        @media (min-width: 880px) {
          display: flex;
          gap: 64px;
        }
        &:last-child {
          margin-bottom: 0;
        }
        &.border {
          border-bottom: 1px solid #f2f0eb;
        }
        .col.small {
          flex-basis: 25%;
          flex-shrink: 0;
        }
      }
    }
  }
}
