[class^="rb"] {
  .btn {
    background-color: $affinity-blue;
    border-radius: 6px;
    color: white;
    display: inline-block;
    padding: 0.6em 1.2em;
    text-align: center;
    text-decoration: none;
    transition: all 300ms ease;
    @media (max-width: 550px) {
      width: 100%;
    }
    &:hover,
    &:active,
    &:focus {
      background-color: $green;
      color: $affinity-blue;
    }
  }
}
