.rb__block-image-content {
  margin: $section-margin 0;
  @media (min-width: $tablet-width) {
    margin: $section-margin-large 0;
  }
  &.beige {
    background-color: $warm-neutral;
    margin: 0;
    padding: $section-padding 0;
    @media (min-width: $tablet-width) {
      padding: $section-padding-large 0;
    }
    .contain .content-wrapper {
      @media (min-width: 1000px) {
        flex-direction: row-reverse;
      }
    }
  }
  .contain {
    > img {
      max-width: 550px;
      margin: 0 auto 3em;
    }
    .content-wrapper {
      align-items: center;
      display: flex;
      flex-direction: column-reverse;
      gap: 32px;
      @media (min-width: 1000px) {
        gap: 40px;
        flex-direction: row;
      }
      .left,
      .right {
        flex-basis: 100%;
        img {
          height: auto;
          width: 100%;
        }
        ul.checkmarks li {
          list-style-type: none;
          padding-left: 8px;
          position: relative;
          margin-bottom: 24px;
          &:before {
            background-image: url("data:image/svg+xml,%3Csvg width='37' height='38' viewBox='0 0 37 38' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.14 0.985352C8.14 0.985352 0 9.12535 0 19.1254C0 29.1254 8.14 37.2654 18.14 37.2654C28.14 37.2654 36.28 29.1254 36.28 19.1254C36.28 9.12535 28.14 0.985352 18.14 0.985352ZM27.44 14.1254L15.68 25.8854C15.44 26.1254 15.12 26.2454 14.78 26.2454C14.44 26.2454 14.14 26.1254 13.88 25.8854L8.84 20.8454C8.34 20.3454 8.34 19.5654 8.84 19.0654C9.34 18.5654 10.14 18.5654 10.62 19.0654L14.76 23.2054L25.62 12.3454C26.12 11.8454 26.92 11.8454 27.4 12.3454C27.88 12.8454 27.9 13.6254 27.4 14.1254H27.44Z' fill='%233D479C'/%3E%3C/svg%3E%0A");
            background-size: 100%;
            content: "";
            height: 1.5em;
            width: 1.5em;
            display: block;
            position: absolute;
            left: calc(-1.5em - 8px);
            top: 0;
          }
          &::marker {
            content: "";
            display: none;
          }
        }
      }
    }
  }
}
